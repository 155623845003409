import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
// import { Link } from 'react-router-dom';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { Select } from 'semantic-ui-react';

import Link from 'components/Link/Link';
import Parse from 'widget/parse';


const hostname =
  (process.env.BROWSER &&
    global &&
    global.window &&
    global.window.location.hostname) ||
  '';

const titleArr = [
  { key: '1', value: 'NlJ4XyyAI8', text: '🏫 学院について' },
  { key: '2', value: 'F720WD3j1G', text: '📚 コース' },
  { key: '3', value: 'j7duR9by4P', text: '🎓 進路・進学' },
  { key: '4', value: '2NvlC4HxgG', text: '📷 年間行事' },
  { key: '5', value: 'ZH9MSGI80e', text: '📖 公開資料' },
]

const itemArr = [
  { key: '1_1', value: '1_1', text: 'ごあいさつ', titleValue: '1', pageId: 'NlJ4XyyAI8' },
  { key: '1_2', value: '1_2', text: '愛知国際学院とは', titleValue: '1', pageId: 'NlJ4XyyAI8' },
  { key: '1_3', value: '1_3', text: '施設紹介', titleValue: '1', pageId: 'NlJ4XyyAI8' },
  { key: '1_4', value: '1_4', text: '校舍', titleValue: '1', pageId: 'NlJ4XyyAI8' },
  { key: '2_1', value: '2_1', text: 'コース', titleValue: '2', pageId: 'F720WD3j1G' },
  { key: '2_2', value: '2_2', text: '入学金・学費', titleValue: '2', pageId: 'F720WD3j1G' },
  { key: '3_1', value: '3_1', text: '進路・進学', titleValue: '3', pageId: 'j7duR9by4P' },
  { key: '4_1', value: '4_1', text: '年間行事', titleValue: '4', pageId: '2NvlC4HxgG' },
  { key: '5_1', value: '5_1', text: '公開資料', titleValue: '5', pageId: 'ZH9MSGI80e' },
]


class DropdownSitemap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleId: '',
    };
  }

  componentDidMount = async () => {
    console.log("enter dropdownsitemap")
    const { cookies } = this.props;
    const docLang = cookies.get('docLang');
    const newtitleId = titleArr[0].value;

    const webpageArr = await Parse.queryData('webpage', { companyId: 'Wuwrcczn0Z', })
    console.log("webpageArr:", webpageArr)
    // const itemArr = await Parse.queryData('webpage', { companyId: 'Wuwrcczn0Z' })//.map(({ pageId }) => (pageId))
    // console.log("itemArr:", itemArr)
    // const itemArr = (await Parse.queryData('webpage', { companyId: 'Wuwrcczn0Z' })).map(({ pageId, text2 }) => (pageId, text2))
    // console.log("itemArr:", itemArr)
    this.setState({ titleId: newtitleId, webpageArr })
  }

  changeLang = (lang, docLang = 'en', dbLang) => {
    const { cookies } = this.props;
    cookies.set('lang', lang, { path: '/' });
    cookies.set('docLang', docLang, { path: '/' });
    cookies.set('dbLang', dbLang, { path: '/' });
    document.documentElement.lang = docLang;
    window.location.reload(true);
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  render() {
    const { titleId = '', webpageArr = [] } = this.state
    return (
      <>
        <UncontrolledDropdown className="btn-rotate" nav>
          <DropdownToggle color="default" nav>
            <i className="fa fa-sitemap" />
          </DropdownToggle>
          <DropdownMenu aria-labelledby="navbarDropdownMenuLink2" className="dropdown-danger" right>
            <DropdownItem header>
              <Select name='titleId' value={titleId} options={titleArr} onChange={this.handleChange} />
            </DropdownItem>
            {webpageArr.filter(item => item.pageId === titleId).map(({ pageId, text2, objectId }) => (
              <DropdownItem key={pageId}>
                <Link
                  className="nav-link"
                  to={`/${objectId}`}
                  tag={Link}
                >
                  {text2}
                </Link>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }
}

export default DropdownSitemap;
