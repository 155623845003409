import React from 'react';
// import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Headroom from 'headroom.js';
// import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
// import intl from 'react-intl-universal';
import { setCompany } from 'store/actions/comp';
import { Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, Container, UncontrolledTooltip, } from 'reactstrap';
import Link from 'components/Link/Link';
import { Image, Select, Button } from 'semantic-ui-react';

import { logout } from 'store/actions/auth';
import DropdownLang1 from './DropdownLang1';
import DropdownSitemap from './DropdownSitemap';
import ChgCompany from '../admin/ChgCompany';
import { PageHeader } from 'antd';

const hostname = (process.env.BROWSER && global && global.window && global.window.location.hostname) || '';

const titleArr = [
  { key: '1', value: 'NlJ4XyyAI8', text: '🏫 学院について' },
  { key: '2', value: 'F720WD3j1G', text: '📚 コース' },
  { key: '3', value: 'dn1h9pMeL3', text: '📝 カリキュラム' },
  { key: '4', value: 'j7duR9by4P', text: '🎓 進路・進学' },
  { key: '5', value: '2NvlC4HxgG', text: '📷 年間行事' },
  { key: '6', value: 'ZH9MSGI80e', text: '📖 公開資料' },
]

const ColorNavbar = (props = {}) => {
  const [navbarColor, setNavbarColor] = React.useState('navbar-transparent');
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const newtitleId = titleArr[0].value;
  const [titleId, setTitleId] = React.useState(newtitleId);
  const larger = 1.5  //初始為1
  const {
    history,
    cookies,
    companyObj = {},
    user = {},
    webnavArr = [],
    webpageArr = [],
    companies = [],
    changeCompany,
  } = props;
  // const dbLang = (cookies && cookies.get('dbLang')) || 'name';
  const dbLang = 'name';
  const dbLang1 = (cookies && cookies.get('dbLang')) || 'name';
  // const logout = () => {
  // const toUrl = companyObj.useOfficial ? "/" : '/auth/login';
  // const toUrl = '/auth/login';
  // window.location.href = window.location.hostname + toUrl;
  //   props.logout(props.history, '/');
  // };
  React.useEffect(() => {
    const headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor('');
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor('navbar-transparent');
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });
  const data1 = webnavArr.filter(
    item =>
      item.level === '1' &&
      (item.pageType === '1' || item.pageType === '2' || item.pageType === '3'),
  );
  // console.log("data1:", data1)
  // console.log("companyObj:", companyObj)
  const profile =
    (user.isAuthenticated && user.profile && user.profile.toJSON()) || {};
  const [name = '', host] = user.isAuthenticated
    ? profile.username && profile.username.split('@')
    : ['', ''];

  let showUser = true;
  if (companyObj.objectId === 'aKDM0xR8py' && !user.isAuthenticated) {
    showUser = false;
  }

  const handleChange = (e, { name, value }) => {
    setTitleId(value)
  }

  const { themeConf = {} } = companyObj
  const { img1File = {}, } = themeConf
  return (<>
    {bodyClick ? (
      <div
        id="bodyClick"
        onClick={() => {
          document.documentElement.classList.toggle('nav-open');
          setBodyClick(false);
          setCollapseOpen(false);
        }}
      />
    ) : null}
    {companyObj.objectId === 'Wuwrcczn0Z' ? (<>
      <Navbar
        className={classnames('fixed-top', navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>
              <p style={{ color: '#526ca8', textAlign: 'left', fontSize: `${12 * larger}px`, fontFamily: 'Arial Black' }}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;since 1990</p>
              {img1File.url ? <Image size='large' src={img1File.url} /> : companyObj.name}
              <p style={{ color: '#526ca8', textAlign: 'center', fontSize: `${26 * larger}px`, fontFamily: 'cursive', fontWeight: 'bold' }}>舞台は世界　学問に国境なし</p>
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              {/* {intl.get('愛知国際学院')} */}
              {companyObj[dbLang]}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {data1
                .filter(item => item.isOpen)
                .map(item =>
                  item.pageType === '2' || item.pageType === '3' ? (
                    <UncontrolledDropdown nav inNavbar key={item.objectId}>
                      {webnavArr
                        .filter(
                          item2 =>
                            item2.isOpen &&
                            item2.level === '2' &&
                            item2.nav1Id === item.objectId,
                        )
                        .map(element => (
                          <DropdownItem
                            key={element.objectId}
                            id={`b${element.objectId}`}
                            to={`${item.pageType === '3' ? '/#' : '/page/'}${element.path2 || element.objectId}`}
                            style={{ fontSize: 'x-large' }}
                            tag={Link}
                          >
                            {/* {element[dbLang]} */}
                            {element['name' + dbLang1]}
                          </DropdownItem>
                        ))}
                    </UncontrolledDropdown>
                  ) : (
                    <NavItem key={item.objectId}>
                      <Link
                        id={`a${item.objectId}`}
                        className="nav-link"
                        to={`/page/${item.path1 || item.objectId}`}
                        tag={Link}
                        style={{ fontSize: 'x-large' }}
                      >
                        {item[dbLang]}
                      </Link>
                    </NavItem>
                  ),
                )}
              {companyObj.isMultiLang ? <DropdownLang1 {...props} /> : null}
              {/* <DropdownSitemap {...props} /> */}
              <UncontrolledDropdown className="btn-rotate" nav>
                <DropdownToggle color="default" nav>
                  <i className="fa fa-sitemap" />
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink2" className="dropdown-danger" right>
                  <DropdownItem header>
                    <Select name='titleId' value={titleId} options={titleArr} onChange={handleChange} />
                  </DropdownItem>
                  {webpageArr.filter(item => item.pageId === titleId && item.componentId !== 'PageHeader' && item.text2).map(element2 => (
                    <DropdownItem
                      key={element2.objectId}
                      className="nav-link"
                      id={`a${element2.objectId}`}
                      to={`/#${element2.objectId}`}
                      style={{ fontSize: 'x-large' }}
                      tag={Link}
                    >
                      {element2.text2}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* {showUser ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle caret color="default" nav>   <i className="fa fa-user-o" /> </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right>
                    <DropdownItem header>{name}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem to="/profile" tag={Link}>個人資料</DropdownItem>
                    <DropdownItem to="/webDesign" tag={Link}>網站設計</DropdownItem>
                    {companyObj.isMultiLang ? (<DropdownItem to="/localeManage" tag={Link}>多國語系</DropdownItem>) : null}
                    <DropdownItem href="/admin">後台管理</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="#" onClick={logout}>登出</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null} */}
              {/* {hostname === 'localhost' ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle caret color="default" nav><i className="fa fa-desktop" /></DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right  >
                    {companies.length &&
                      companies.map(({ name, objectId }) => (
                        <DropdownItem
                          key={`ab${objectId}`}
                          onClick={e => {
                            e.preventDefault();
                            changeCompany(objectId);
                            history.push('/');
                          }}
                        >
                          {name}
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null} */}
              {/* <ChgCompany /> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
    ) : (
      <Navbar className={classnames('fixed-top', navbarColor)} expand="lg" id="navbar-main" >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>{companyObj[dbLang]}</NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              {/* {intl.get('愛知国際学院')} */}
              {companyObj[dbLang]}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {data1
                .filter(item => item.isOpen)
                .map(item =>
                  item.pageType === '2' || item.pageType === '3' ? (
                    <UncontrolledDropdown nav inNavbar key={item.objectId}>
                      <DropdownToggle color="default" nav caret>
                        {item[dbLang]}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-danger" right>
                        {webnavArr
                          .filter(
                            item2 =>
                              item2.isOpen &&
                              item2.level === '2' &&
                              item2.nav1Id === item.objectId,
                          )
                          .map(element => (
                            <DropdownItem
                              key={element.objectId}
                              id={`b${element.objectId}`}
                              to={`${item.pageType === '3' ? '/#' : '/page/'
                                }${element.path2 || element.objectId}`}
                              tag={Link}
                            >
                              {element[dbLang]}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  ) : (
                    <NavItem key={item.objectId}>
                      <Link
                        id={`a${item.objectId}`}
                        className="nav-link"
                        to={`/page/${item.path1 || item.objectId}`}
                        tag={Link}
                      >
                        {item[dbLang]}
                      </Link>
                    </NavItem>
                  ),
                )}
              {companyObj.isMultiLang ? <DropdownLang1 {...props} /> : null}
              {showUser ? (
                <>
                  {/* <Button color='blue' basic inverted style={{ margin: '15px' }} onClick={() => history.push('/register')}>人臉機註冊入口</Button> */}
                  <Button color='blue' basic inverted style={{ margin: '15px' }} as='a' href='/register'>人臉辨識機註冊入口</Button>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle caret color="default" nav>
                      <i className="fa fa-user-o" />
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right      >
                      <DropdownItem header>{name}</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem to="/profile" tag={Link}>
                        個人資料
                      </DropdownItem>
                      <DropdownItem to="/front/startUpEdit" tag={Link}>
                        網站設計
                      </DropdownItem>
                      {companyObj.isMultiLang ? (<DropdownItem to="/localeManage" tag={Link}>多國語系</DropdownItem>) : null}
                      <DropdownItem href="/admin">後台管理</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem href="#" onClick={logout} >登出</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>) : null}
              {hostname === 'localhost' ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle caret color="default" nav>
                    <i className="fa fa-desktop" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right  >
                    {companies.length &&
                      companies.filter(item => item.useOfficial).map(({ name, objectId }) => (
                        <DropdownItem
                          key={`ab${objectId}`}
                          onClick={e => {
                            e.preventDefault();
                            changeCompany(objectId);
                            history.push('/');
                          }}
                        >
                          {name}
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>)
    }
  </>);
};

// export default ColorNavbar;
const mapState = state => ({
  // availableCompanies: state.runtime.availableCompanies,
  user: state.user,
  // currentCompany: state.comp.companyId || '',
});
const mapDispatch = {
  setCompany,
  logout,
};
// export default withCookies(ColorNavbar);
export default connect(mapState, mapDispatch)(ColorNavbar);
